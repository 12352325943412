import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Fooldal from './pages/Fooldal';
import Navigator from './pages/Navigator';
import Feladatok from './pages/Feladatok';
import Fejezetek from './pages/Fejezetek';
import Statisztikak from './pages/Statisztikak';
import SuccessPage from './pages/SuccessPage';
import FailurePage from './pages/FailurePage';
import BillingForm from './components/BillingForm';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Fooldal />} />
          <Route path="/navigator" element={<Navigator />} />
          <Route path="/navigator/:exerciseId" element={<Navigator />} />
          <Route path="/feladatok" element={<Feladatok />} />
          <Route path="/fejezetek" element={<Fejezetek />} />
          <Route path="/statisztikak" element={<Statisztikak />} />
          <Route path="/payment/success" element={<SuccessPage />} />
          <Route path="/payment/failure" element={<FailurePage />} />
          <Route path="/billing-form" element={<BillingForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
