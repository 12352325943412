import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const StartPaymentButton = ({ text }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/billing-form');
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            style={{ minWidth: 'auto' }}
        >
            {text}
        </Button>
    );
};

export default StartPaymentButton;
