import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Typography, Box, CircularProgress } from '@mui/material';
import Footer from '../components/Footer';

const SuccessPage = () => {
  const { user, isLoading } = useAuth0();

  useEffect(() => {
    if (user) {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');
      if (!sessionId) {
        console.error('Session ID is null');
        return;
      }

      console.log('Session ID:', sessionId);

      const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
      const appUrl = process.env.REACT_APP_APP_URL;

      // Check if the user is a Google user
      const isGoogleUser = user.sub.startsWith("google");

      // Extract the name based on the user type
      const name = isGoogleUser ? user.name : user[`${appUrl}/name`];
      const username = isGoogleUser ? user.nickname : user.username
      
      axios.post(`${paymentApiUrl}/store-payment-info`, {
        email: user.email,
        user_id: user.sub,
        session_id: sessionId,
        payment_status: 'succeeded',
        name: name,
        username: username,
      }).then(response => {
        console.log('Payment info stored successfully');
      }).catch(error => {
        console.error('Error storing payment info:', error);
      });
    }
  }, [user]);

  if (isLoading) {
    return <CircularProgress style={{marginTop: '100px'}}/>;
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: '1000px', paddingBottom: '100px' }}>
      <Box mt={10}>
        <Typography variant="h4" align="center"><strong>Sikeres fizetés!</strong></Typography>
        <Typography mt={2} align="center">
          Köszönjük, hogy a kemszam.hu-t választottad.
          <br /> Mostantól 365 napig hozzáférsz a feladatokhoz.
        </Typography>
      </Box>
      <Footer />
    </div>
  );
};

export default SuccessPage;
