import React, { useEffect, useState } from 'react';
import { Typography, Link, Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import { Facebook, Instagram, YouTube } from '@mui/icons-material';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [footerStyle, setFooterStyle] = useState({});

    useEffect(() => {
        const handleResize = () => {
            if (isMobile) {
                setFooterStyle({ position: 'relative', width: '100%' });
            } else {
                setFooterStyle({ position: 'fixed', bottom: 0, width: '100%' });
            }
        };

        // Initial check
        handleResize();

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);

    return (
        <footer style={{ 
            ...footerStyle, 
            left: 0, 
            right: 0, 
            backgroundColor: 'white',
            zIndex: 1000  // Ensure it stays on top
        }}>
            <Box sx={{ paddingX: 3 }}>
                <Grid container alignItems="center" py={1} borderTop="1px solid #ccc">
                    <Grid item xs={12} sm={4} container justifyContent={isMobile ? 'center' : 'flex-start'} alignItems="center" mb={isMobile ? 1 : 0}>
                        <Typography variant="body2" color="textSecondary" sx={{ fontSize: 14 }}>
                            &copy; {currentYear} kemszam.hu
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} container justifyContent="center" mb={isMobile ? 1 : 0}>
                        <Typography variant="body2" color="textSecondary" sx={{ fontSize: 14, textAlign: 'center' }}>
                            made with <span style={{ color: 'red' }}>❤️</span> by <Link href="https://lvlmeup.hu" target="_blank" rel="noopener" color="textSecondary" underline="always">lvlmeup.hu</Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} container justifyContent={isMobile ? 'center' : 'flex-end'}>
                        <Link href="https://www.youtube.com/@lvlmeup" target="_blank" rel="noopener" color="textSecondary" underline="none" component="a" sx={{ marginRight: 1 }}>
                            <YouTube sx={{ fontSize: 28 }} />
                        </Link>
                        <Link href="https://www.instagram.com/lvlup_science/" target="_blank" rel="noopener" color="textSecondary" underline="none" component="a" sx={{ marginRight: 1 }}>
                            <Instagram sx={{ fontSize: 28 }} />
                        </Link>
                        <Link href="https://www.facebook.com/lvlmeup" target="_blank" rel="noopener" color="textSecondary" underline="none" component="a">
                            <Facebook sx={{ fontSize: 28 }} />
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </footer>
    );
};

export default Footer;
