import React from 'react';
import { Button, TextField, IconButton, Box, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';

const ExerciseNavigator = ({
  exerciseId,
  maxId,
  handlePreviousExercise,
  handleNextExercise,
  handleRandomExercise,
  handleExerciseIdChange,
  handleKeyPress,
  handleBlur,
  handleGoToExercise,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }} // Column on small screens, row on larger screens
      alignItems="center"
      justifyContent="center"
      mt={6}
      sx={{
        textAlign: { xs: 'center', sm: 'left' }, // Center text on small screens
      }}
    >
      <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 0 }}>
        <IconButton onClick={handlePreviousExercise}>
          <ArrowBackIosIcon />
        </IconButton>
        <TextField
          type="number"
          placeholder="42"
          value={exerciseId}
          onChange={(e) => handleExerciseIdChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleKeyPress();
            }
          }}
          onBlur={handleBlur}
          variant="outlined"
          size="small"
          style={{ marginRight: '10px', width: '90px' }}
        />
        {maxId && <Typography variant="body1">{`/ ${maxId}`}</Typography>}
        <IconButton onClick={handleNextExercise}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size={isSmallScreen ? 'small' : 'medium'} // Change button size based on screen size
        onClick={handleGoToExercise}
        sx={{
          marginTop: { xs: 2, sm: 0 },
          marginLeft: { xs: 0, sm: '10px' },
          alignSelf: { xs: 'center', sm: 'auto' }, // Center button on small screens
        }}
      >
        Ugrás a feladathoz
      </Button>
    </Box>
  );
};

export default ExerciseNavigator;
