import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Box, Grid, CircularProgress, Tab, Tabs, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Footer from '../components/Footer';
import LoginButton from '../components/Login';
import ChapterCard from '../components/ChapterCard';
import CustomCard from '../components/Card';
import StartPaymentButton from '../components/StartPaymentButton';
import './Fejezetek.css';

const apiUrl = process.env.REACT_APP_API_URL;
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ minHeight: '400px' }} // Set a fixed minimum height
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Fejezetek = () => {
  const { isAuthenticated, user } = useAuth0();
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [exercises, setExercises] = useState([]);
  const [chapterADescription, setChapterADescription] = useState(''); // ChapterA description
  const [chapterBDescription, setChapterBDescription] = useState(''); // ChapterB description
  const [value, setValue] = useState(0); // Default tab index
  const [hasAccess, setHasAccess] = useState(false);
  const topLevelTabsRef = useRef(null); // Reference to the top-level tabs

  const tabsRef = useRef(null);

  // Function to load MathJax
  const loadMathJax = () => {
    if (window.MathJax) {
      window.MathJax.typesetPromise().catch((err) => console.log("MathJax error: ", err));
    }
  };

  useEffect(() => {
    const fetchChapters = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/chapters`);
        if (!response.ok) {
          throw new Error('Failed to fetch chapters');
        }
        const data = await response.json();
        setChapters(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChapters();
  }, []);

  useEffect(() => {
    const checkAccess = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await fetch(`${paymentApiUrl}/check-access`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: user.email }),
          });
          const data = await response.json();
          setHasAccess(data.has_access);
        } catch (error) {
          console.error("Error checking access:", error);
          setHasAccess(false);
        }
      }
    };

    checkAccess();
  }, [isAuthenticated, user]);

  const fetchChapterDescriptions = async (chapterBId) => {
    try {
      const response = await fetch(`${apiUrl}/chapterB/${chapterBId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch chapter descriptions');
      }
      const data = await response.json();
      setChapterADescription(data.chapterA_description || '');
      setChapterBDescription(data.description || '');
      setTimeout(loadMathJax, 0); // Load MathJax after setting the content
    } catch (error) {
      console.error('Error fetching chapter descriptions:', error);
    }
  };

  const fetchExercisesForChapter = async (chapterB, responseStatus = 'all') => {
    setLoading(true);
    setError(null);
    try {
      let url;
      if (responseStatus === 'all') {
        url = `${apiUrl}/all_exercises_by_chapter?chapterB_id=${chapterB}`;
      } else {
        url = `${apiUrl}/chapters/${chapterB}/exercises?user_id=${encodeURIComponent(user.sub)}&response_status=${responseStatus}`;
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch exercises');
      }
      const data = await response.json();
      setExercises(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChapterCardClick = (chapter) => {
    if (chapter && chapter.chapterB_id) {
      setSelectedChapter(chapter);
      fetchChapterDescriptions(chapter.chapterB_id);
      setValue(0); // Default to "Leírás" tab
    } else {
      console.error('Chapter ID is missing:', chapter);
    }
  };

  useEffect(() => {
    if (selectedChapter && topLevelTabsRef.current) {
      const navbarHeight = 130; // Replace with your navbar's height in pixels
      const elementPosition = topLevelTabsRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }, [selectedChapter]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue !== 0 && selectedChapter) {
      fetchExercisesForChapter(selectedChapter.chapterB_id, valueToFilter(newValue));
    }
  };

  const valueToFilter = (value) => {
    switch (value) {
      case 1:
        return 'all';
      case 2:
        return 'nem_ment';
      case 3:
        return 'sikerult';
      case 4:
        return 'meg_nem_probaltam';
      default:
        return 'all';
    }
  };

  const getNoExercisesMessage = () => {
    switch (value) {
      case 2:
        return 'Nincs nem sikerült feladat.';
      case 3:
        return 'Nincs sikerült feladat.';
      case 4:
        return 'Nincs még nem próbált feladat.';
      default:
        return 'Nincs elérhető feladat.';
    }
  };

  const handleScroll = (direction) => {
    const tabCount = 5; // Number of tabs
    let newValue;
    if (direction === 'left') {
      newValue = value > 0 ? value - 1 : tabCount - 1;
    } else if (direction === 'right') {
      newValue = value < tabCount - 1 ? value + 1 : 0;
    }
    setValue(newValue);
    fetchExercisesForChapter(selectedChapter.chapterB_id, valueToFilter(newValue)); // Ensure the content loads
  };

  if (!isAuthenticated) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Hogy lásd milyen fejezetekből áll a számolási példatár és kedvedre böngéssz a feladatok között, kérlek jelentkezz be!
        </Typography>
        <LoginButton />
        <Footer />
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Az elérés lejárt vagy nem elérhető. Kérlek frissítsd az előfizetésed!
        </Typography>
        <StartPaymentButton text="Megvásárolom (5.990 Ft / év)"/>
        <Footer />
      </div>
    );
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: '1000px'}}>
      <Box mt={3} mb={2} paddingBottom={10}>
        <Typography variant="h5" mb={3}>Fejezetek</Typography>
        <Grid container spacing={3}>
          {chapters.map((chapter, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ChapterCard chapter={chapter} onClick={() => handleChapterCardClick(chapter)} />
            </Grid>
          ))}
        </Grid>
        {selectedChapter && (
          <div ref={topLevelTabsRef}>
            <Typography variant="h5" mt={7} mb={3}>Feladatok: {selectedChapter.chapterB}</Typography>
            <Box className="tabs-container">
              <IconButton 
                onClick={() => handleScroll('left')} 
                sx={{ 
                  position: 'absolute', 
                  left: 0, 
                  zIndex: 1, 
                  display: { xs: 'block', md: 'none' } // Hide on larger screens
                }}
              >
                <ArrowBack />
              </IconButton>
              <Box className="tabs-wrapper">
                <Tabs
                  ref={tabsRef}
                  value={value}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  TabIndicatorProps={{ style: { backgroundColor: '#1976d2' } }}
                >
                  <Tab label="Elméleti összefoglaló" {...a11yProps(0)} className="tab-root" />
                  <Tab label="Összes" {...a11yProps(1)} className="tab-root" />
                  <Tab label="Nem sikerült" {...a11yProps(2)} className="tab-root" />
                  <Tab label="Sikerült" {...a11yProps(3)} className="tab-root" />
                  <Tab label="Nem próbáltam" {...a11yProps(4)} className="tab-root" />
                </Tabs>
              </Box>
              <IconButton 
                onClick={() => handleScroll('right')} 
                sx={{ 
                  position: 'absolute', 
                  right: 0, 
                  zIndex: 1, 
                  display: { xs: 'block', md: 'none' } // Hide on larger screens
                }}
              >
                <ArrowForward />
              </IconButton>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                <Typography
                      mt={2}
                      mb={3}
                      align="left"
                      style={{ fontSize: '20px' }}
                  >
                      <strong>Főfejezet - {selectedChapter.chapterA}: </strong>
                </Typography>
                <Typography
                  mt={2}
                  align="left"
                  className="mathjax-content"
                  style={{ fontSize: '17px', textAlign: 'left', marginLeft: '10px', marginRight: '10px' }}
                  dangerouslySetInnerHTML={{ __html: chapterADescription }}
                />
                <Typography
                      mt={5}
                      mb={3}
                      align="left"
                      style={{ fontSize: '20px' }}
                  >
                      <strong>Alfejezet - {selectedChapter.chapterB}:</strong>
                </Typography>
                <Typography
                  mt={2}
                  mb={2}
                  align="left"
                  className="mathjax-content"
                  style={{ fontSize: '17px', textAlign: 'left', marginLeft: '10px', marginRight: '10px' }}
                  dangerouslySetInnerHTML={{ __html: chapterBDescription }}
                />
              </Box>
            </CustomTabPanel>
            {[1, 2, 3, 4].map((index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {exercises.length === 0 ? (
                      <Typography>{getNoExercisesMessage()}</Typography>
                    ) : (
                      <Grid container spacing={3}>
                        {exercises.map((exercise) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={exercise.id}>
                            <CustomCard exercise={exercise} />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {/* Filler box to ensure consistent space */}
                    <Box sx={{ minHeight: '250px' }}></Box>
                  </>
                )}
              </CustomTabPanel>
            ))}
          </div>
        )}
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      <Footer />
    </div>
  );
};

export default Fejezetek;
