import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const useExerciseData = () => {
  const location = useLocation();
  const initialExerciseId = location.state?.exerciseNumber || null; // Change to null

  const [exerciseId, setExerciseId] = useState(initialExerciseId);
  const [exerciseContent, setExerciseContent] = useState(null);
  const [maxId, setMaxId] = useState(null);

  const fetchExercise = useCallback(async (id) => {
    try {
      const response = await fetch(`${apiUrl}/exercises/${id}`);
      if (!response.ok) throw new Error('Failed to fetch exercise');
      const data = await response.json();
      setExerciseContent(data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);

  const fetchMaxId = async () => {
    try {
      const response = await fetch(`${apiUrl}/max_id`);
      if (!response.ok) throw new Error('Failed to fetch max ID');
      const data = await response.json();
      setMaxId(data.max_id);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchMaxId();
  }, []);

  useEffect(() => {
    if (initialExerciseId !== null) {
      fetchExercise(initialExerciseId);
    }
  }, [initialExerciseId, fetchExercise]);

  const handlePreviousExercise = () => {
    if (exerciseId > 1) {
      const prevId = parseInt(exerciseId, 10) - 1;
      setExerciseId(prevId);
      fetchExercise(prevId);
    }
  };

  const handleNextExercise = () => {
    if (exerciseId < maxId) {
      const nextId = parseInt(exerciseId, 10) + 1;
      setExerciseId(nextId);
      fetchExercise(nextId);
    }
  };

  const handleRandomExercise = () => {
    const randomId = Math.floor(Math.random() * maxId) + 1;
    setExerciseId(randomId);
    fetchExercise(randomId);
  };

  const handleGoToExercise = () => {
    fetchExercise(exerciseId);
  };

  const handleExerciseIdChange = (e) => {
    setExerciseId(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchExercise(exerciseId);
    }
  };

  const handleBlur = () => {
    fetchExercise(exerciseId);
  };

  return {
    exerciseId,
    maxId,
    exerciseContent,
    handlePreviousExercise,
    handleNextExercise,
    handleRandomExercise,
    handleGoToExercise,
    handleExerciseIdChange,
    handleKeyPress,
    handleBlur,
    setExerciseId,
    fetchExercise,
    showSolution: (solution, explanation) => {
      setExerciseContent((prevContent) => ({
        ...prevContent,
        showSolution: !prevContent.showSolution,
        solution,
        explanation,
      }));
    },
  };
};

export default useExerciseData;
