import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const ChapterCard = ({ chapter, onClick }) => {
  return (
    <Card sx={{ boxShadow: 4, cursor: 'pointer', height: 90 }} onClick={onClick}>
      <CardContent>
        <Typography variant="body1" component="div">
          <strong>{chapter.chapterB}</strong>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {chapter.chapterA}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ChapterCard;
