import React from 'react';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentButton = ({ billingInfo, setLoading, loading }) => {
    const { user } = useAuth0();

    // Function to validate required billing info fields
    const validateBillingInfo = () => {
        const { billing_name, country, postal_code, city, address } = billingInfo;
        return billing_name && country && postal_code && city && address;
    };

    const handleClick = async () => {
        const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;

        if (!paymentApiUrl) {
            console.error('Payment API URL not found');
            return;
        }

        // Check if all required fields are filled
        if (!validateBillingInfo()) {
            alert('Please fill in all required fields.');
            return;
        }

        // Get Stripe.js instance
        const stripe = await stripePromise;

        try {
            setLoading(true);

            // Save billing info first
            await axios.post(`${paymentApiUrl}/store-billing-info`, {
                user_id: user.sub,
                billing_name: billingInfo.billing_name,
                country: billingInfo.country,
                city: billingInfo.city,
                postal_code: billingInfo.postal_code,
                address: billingInfo.address,
                vat_number: billingInfo.vat_number // Optional field
            });

            // Create checkout session
            const response = await fetch(`${paymentApiUrl}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.email,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const session = await response.json();

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                console.error(result.error.message);
                alert(result.error.message);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('Error creating checkout session. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={loading || !validateBillingInfo()}
            style={{ minWidth: 'auto', marginTop: '20px' }}
        >
            {loading ? 'Betöltés...' : 'Kártyás fizetés (5.990 Ft / év)'}
        </Button>
    );
};

export default PaymentButton;
