import React, { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const appUrl = process.env.REACT_APP_APP_URL;

const Profile = ({ onClose }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    console.log("User Object:", user);
  }, [user]);

  const profileStyles = {
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px'
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const isGoogleUser = user.sub.startsWith("google");

  return (
    isAuthenticated && (
      <div>
        <img src={user.picture} alt={user.name} />
        <h3 style={profileStyles.text}>{isGoogleUser ? user.name : user[`${appUrl}/name`]}</h3>
        <p style={profileStyles.text}>{isGoogleUser ? `👤${user.nickname}` : `👤${user.username}`}</p>
        <p style={profileStyles.text}>{user.email}</p>
      </div>
    )
  );
};

export default Profile;
