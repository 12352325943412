import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './ExerciseContent.css'; // Import the CSS file

const apiUrl = process.env.REACT_APP_API_URL;

const ExerciseContent = ({ exerciseContent, userId }) => {
    const [responseStatus, setResponseStatus] = useState(null);
    const [isSolutionVisible, setIsSolutionVisible] = useState(false);
    const isWideScreen = useMediaQuery('(min-width:1000px)');

    // Function to load MathJax
    const loadMathJax = () => {
        if (window.MathJax) {
            window.MathJax.typesetPromise()
                .catch((err) => console.log("MathJax error: ", err));
        }
    };

    useEffect(() => {
        const fetchResponseStatus = async () => {
            if (exerciseContent && userId) {
                try {
                    const result = await fetch(`${apiUrl}/user_response_status/?user_id=${userId}&exercise_id=${exerciseContent.id}`);
                    if (!result.ok) {
                        throw new Error('Failed to fetch response status');
                    }
                    const data = await result.json();
                    setResponseStatus(data.status);
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };

        fetchResponseStatus();
    }, [exerciseContent, userId]);

    useEffect(() => {
        // Reset solution visibility when the exercise content changes
        setIsSolutionVisible(false);
    }, [exerciseContent]);

    useEffect(() => {
        loadMathJax();
    }, [exerciseContent, isSolutionVisible]);

    const handleSolutionToggle = () => {
        setIsSolutionVisible(prevState => !prevState);
    };

    const handleResponse = async (response) => {
        const exerciseId = exerciseContent.id;
        try {
            const result = await fetch(`${apiUrl}/user_responses/?user_id=${userId}&exercise_id=${exerciseId}&response=${response}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!result.ok) {
                throw new Error('Failed to submit response');
            }

            console.log('Response submitted successfully');
            setResponseStatus(response); // Update the status after submitting the response
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!exerciseContent) {
        return (
            <Typography mt={2}>
                Még nem töltöttél be feladatot. Írd be a feladat sorszámát a fenti keretbe <br />
                vagy böngéssz a feladatok között a FELADATOK menüpontban.
                <br />
                <br />
                A FEJEZETEK menüpontban adott témakörben kereshetsz feladatokat.
            </Typography>
        );
    }

    return (
        <Box>
            <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                <Typography
                    mt={2}
                    mb={2}
                    align="center"
                    style={{ fontSize: '17px' }}
                >
                    <strong>{exerciseContent.number}. feladat</strong> (nehézség: {exerciseContent.difficulty})
                </Typography>
                <Typography
                    mt={1}
                    ml={isWideScreen ? 30 : 10}
                    mr={isWideScreen ? 30 : 10}
                    align="left"
                    style={{ display: 'inline-block', fontSize: '17px', textAlign: 'left' }}
                    dangerouslySetInnerHTML={{ __html: exerciseContent.exercise }}
                />
            </Box>
            <Box textAlign="center">
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginTop: '30px' }}
                    onClick={handleSolutionToggle}
                >
                    {isSolutionVisible ? "Rejtsd el a megoldást" : "Mutasd a megoldást"}
                </Button>
            </Box>
            {isSolutionVisible && (
                <>
                    <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                        <Typography
                            mt={5}
                            align="center"
                            style={{ fontSize: '16px' }}
                        >
                            <strong>Megoldás:</strong>
                        </Typography>
                        <Typography
                            mt={1}
                            ml={isWideScreen ? 30 : 6}
                            mr={isWideScreen ? 30 : 6}
                            align="left"
                            className="mathjax-content" // Add className for MathJax content
                            style={{ display: 'inline-block', textAlign: 'left' }}
                            dangerouslySetInnerHTML={{ __html: exerciseContent.solution }}
                        />
                    </Box>
                    <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                        <Typography
                            mt={5}
                            align="center"
                            style={{ fontSize: '16px' }}
                        >
                            <strong>Magyarázat:</strong>
                        </Typography>
                        <Typography
                            mt={1}
                            ml={isWideScreen ? 30 : 10}
                            mr={isWideScreen ? 30 : 10}
                            align="left"
                            className="mathjax-content" // Add className for MathJax content
                            style={{ display: 'inline-block', textAlign: 'left'}}
                            dangerouslySetInnerHTML={{ __html: exerciseContent.explanation }}
                        />
                    </Box>
                </>
            )}
            <Box mt={5} textAlign="center">
                {responseStatus !== null && (
                    <Typography style={{ fontSize: '16px' }}>
                        Utolsó válasz: {responseStatus ? <strong>Sikerült</strong> : <strong>Nem sikerült</strong>}
                    </Typography>
                )}
                <Box mt={1} display="flex" alignItems="center" justifyContent="center">
                    {responseStatus !== null && (
                        <Typography style={{ fontSize: '16px', marginRight: '10px' }}>
                            Megváltoztatom a válaszom:
                        </Typography>
                    )}
                    {responseStatus !== true && (
                        <Button
                            variant="outlined"
                            size="small"
                            color="success"
                            style={{ marginRight: '10px' }}
                            onClick={() => handleResponse(true)}
                            endIcon={<CheckCircleIcon style={{ marginLeft: '-5px', marginRight: '-3px', marginBottom: '2px' }} />}
                        >
                            Sikerült!
                        </Button>
                    )}
                    {responseStatus !== false && (
                        <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={() => handleResponse(false)}
                            endIcon={<CancelIcon style={{ marginLeft: '-6px' , marginRight: '-3px', marginBottom: '2px'}} />}
                        >
                            Nem sikerült
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ExerciseContent;
